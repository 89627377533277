<template>
    <a
      class="product-preview"
      :class="{ 'product-preview--long': isRegionUS }"
      :data-product-link="product.id"
      href="javascript:void;"
    >
      <!-- v-if="product.variant_parent_id == null" -->
      <div class="product-preview__content" :class="{ 'product-preview__content_disabled': (product && !product.suppliers_availablity_type) || product.suppliers_availablity_type == 4 }" ref="productCard">
        <div class="product-preview__box product-preview__box--img">
          <ProductPreviewBadge
            v-if="product.has_local_suppliers"
            class="product-preview__badge"
            :title="$t('product-buy-local')"
          />
          <div class="product-preview__img-wrapper" v-if="showImage">
            <img v-if="product && product.picture && product.picture.url" preload :src="product.picture.url" alt="product" class="product-preview__img" width="100%" />
            <img v-else fetchpriority="high" width="100%" src="~/assets/images/empty-product-small.webp" alt="product" class="product-preview__img" />
          </div>
        </div>
  
        <div class="product-preview__box product-preview__box--button">
          <button
            type="button"
            class="product-preview__button button"
            :class="{ 'button--disabled' : !product.available_suppliers_count}"
            data-product-button-preview-action=""
          >
            {{ textChanger.buttonText }}
          </button>
          <!-- <button
            class="product-preview__mobile-button"
            type="button"
            data-product-button-preview-action=""
          >
            <svg-icon name="actions-cart" class="product-preview__ico"></svg-icon>
          </button> -->
        </div>
  
        <div class="product-preview__box product-preview__box--info">
          <p class="product-preview__info">{{ product.manufacturer }}</p>
          <p class="product-preview__availability">{{ textChanger.availableText }}</p>
          <span class="product-preview__item product-preview__item--title product-preview__title">{{ product.name }}</span>
          <p class="product-preview__info product-preview__info--num" v-if="product.part_number">{{ $t("product-part-num") }} {{ product.part_number }}</p>
          <ProductPrice
            v-if="product && product.suppliers_availablity_type && product.suppliers_availablity_type != 4"
            class="product-price--preview"
            :minPrice="product.min_price"
            :minPriceUsd="product.min_price_usd"
            :isDisabled="!product.available_suppliers_count || product.suppliers_availablity_type === 4"
          />
        </div>
      </div>
    </a>
  </template>
  
  <script>
  import ProductPrice from "@/components/product/ProductPrice.vue";
  import ProductPreviewBadge from "@/components/product/previews/ProductPreviewBadge";
  import { mapGetters } from "vuex";
  import { pluralizeMixin } from "@/mixins/pluralize";
  
  export default {
    data() {
      return {
        showImage:false
      };
    },
    components: {
      ProductPreviewBadge,
      ProductPrice
    },
    props: ["product"],
    mixins: [pluralizeMixin],
    computed: {
      ...mapGetters({
        isRegionUS: "shared/isRegionUS"
      }),
      textChanger(){
        if (this.product?.suppliers_availablity_type) {
          if(this.product.suppliers_availablity_type === 1){
            return{
              availableText: this.$t("product-in-stock"),
              buttonText: this.$t("product-add-to-cart-btn")
            }
          }
          if(this.product.suppliers_availablity_type === 2){
            return{
              availableText: this.$t("product-available-in") + this.pluralize(this.product.picked_up_in_days_for_delivery_count,this.$t("product-available-day")),
              buttonText: this.$t("product-add-to-cart-btn")
            }
          }
          if(this.product.suppliers_availablity_type === 3){
            return{
              availableText: this.$t("product-available-request"),
              buttonText: this.$t("product-view-offer-btn")
            }
          }
          if(this.product.suppliers_availablity_type === 4){
            return{
              availableText: this.$t("product-not-avail"),
              buttonText: this.$t("product-view-offers-btn")
            }
          }
        } else {
          return{
            availableText: this.$t("product-not-avail"),
            buttonText: this.$t("product-view-offers-btn")
          }
        }
      }
    },
    mounted(){
      const elementToObserve = this.$refs.productCard;
      const options = {
        root:null,
        threshold:0.1,
        rootMargin:"0px"
      };
      const observer = new IntersectionObserver(this.intersectionCallback, options);
      observer.observe(elementToObserve);
    },
    methods:{
      intersectionCallback(entries,observer){
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.showImage = true;
          }
        });
      }
    },
    methods:{
      intersectionCallback(entries,observer){
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.showImage = true;
          }
        });
      }
    }
  };
  </script>
  
  <style lang="scss">
  .product-preview {
    display: block;
    position: relative;
    width: 50%;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 1px 0 0 0 var(--color-grey-3), 0 1px 0 0 var(--color-grey-3),
      1px 1px 0 0 var(--color-grey-3), 1px 0 0 0 var(--color-grey-3) inset,
      0 1px 0 0 var(--color-grey-3) inset;
  
    @media (--viewport-tablet) {
      width: 33.3%;
    }
  
    @media (--viewport-desktop) {
      width: 25%;
      height: 440px;
    }
  
    @media (--viewport-large-desktop) {
      height: 467px;
    }
  }
  
  .product-preview--long {
    @media (--viewport-desktop) {
      height: 460px;
    }
  
    @media (--viewport-large-desktop) {
      height: 485px;
    }
  }
  
  .product-preview--disabled {
    .product-preview__box--img {
      opacity: 0.5;
    }
  
    .product-preview__title {
      color: var(--color-grey-5);
    }
  
    .product-preview__availability {
      color: var(--secondary-color-dark);
    }
  
    &:hover {
      .product-preview__box--button {
        display: none;
      }
    }
  }
  
  .product-preview__content {
    display: flex;
    flex-direction: column;
    padding: 12px 12px 15px 12px;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  
    @media (--viewport-tablet) {
      padding: 12px 16px 16px;
    }
  }
  
  .product-preview__box {
    display: block;
    box-sizing: border-box;
  }
  
  .product-preview__box--img {
    position: relative;
    margin-bottom: 17px;
    outline: none;
  }
  
  .product-preview__box--info {
    height: 100%;
    min-height: 175px;
    display: flex;
    flex-direction: column;
    // flex: 1;
    outline: none;
  }
  
  .product-preview__item--title {
    flex: 1;
  }
  
  .product-preview__img-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 70%;
  
    @media (--viewport-tablet) {
      padding-bottom: 105%;
    }
  }
  
  .product-preview__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .product-preview__button {
    margin-bottom: 20px;
    display: none;
  }
  
  .product-preview__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 17px;
    height: 15px;
    fill: #ffffff;
  }
  
  .product-preview__info {
    margin-bottom: 12px;
    font-size: 0.75rem;
    line-height: 1.14;
    color: var(--color-grey-5);
    text-transform: uppercase;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  
    @media (--viewport-mobile) {
      font-size: 0.875rem;
    }
  
    @media (--viewport-tablet) {
      margin-bottom: 17px;
    }
  }
  
  .product-preview__info--num {
    font-size: 0.625rem;
    margin-bottom: 12px;
  
    @media (--viewport-mobile) {
      font-size: 0.75rem;
    }
  
    @media (--viewport-tablet) {
      font-size: 0.875rem;
      display: none;
    }
  }
  
  .product-preview__availability {
    margin-bottom: 8px;
    font-size: 0.625rem;
    line-height: 1.3;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--brand-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  
    @media (--viewport-mobile) {
      font-size: 0.75rem;
    }
  }
  
  .product-preview__title {
    margin-bottom: 8px;
    height: 46px;
    font-family: var(--secondary-font);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.125;
    color: var(--secondary-color-dark);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-feature-settings: "pnum" on, "lnum" on;
  
    @media (--viewport-mobile) {
      height: 54px;
      font-size: 1rem;
    }
  
    @media (--viewport-tablet) {
      font-size: 1.25rem;
      height: 72px;
      line-height: 1.2;
    }
  }
  
  .product-preview__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .product-price--preview {
    display: flex;
    justify-content: flex-start;
  }
  
  .product-preview--long {
    .product-preview__content {
      min-height: 340px;
    }
  }
  .product-preview__content_disabled{
    height: 100%;
  }
  
  .product-preview--slider {
      width: auto;
      height: 100%;
      vertical-align: top;
      border: none;
      z-index: 0;
      box-sizing: border-box;
  
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 1px;
        right: 1px;
        z-index: 1;
      }
  
      @media (--viewport-tablet) {
        height: 100%;
        min-height: 440px;
      }
  
      @media (--viewport-desktop) {
        height: 455px;
        &.product-preview--long {
          height: 475px;
        }
      }
  
      @media (--viewport-large-desktop) {
        height: 470px;
        &.product-preview--long {
          height: 490px;
        }
      }
  
      .product-preview__content {
        min-height: 328px;
        height: 100%;
        @media (--viewport-tablet) {
          min-height: 440px;
        }
      }
  
      &:hover {
        @media (--viewport-tablet) {
          z-index: 2;
  
          .product-preview__content {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: auto;
            background: #ffffff;
            transform-origin: center 10%;
            z-index: 2;
  
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              width: calc(100% + 8px);
              height: calc(100% + 8px);
              background-color: #ffffff;
              box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
              z-index: -1;
            }
          }
  
          .product-preview__button {
            display: block;
          }
  
          .product-preview__info--num {
            display: block;
          }
        }
      }
    }
  
  </style>
  