<template>
  <div class="product-carousel">
    <div class="product-carousel__wrapper wrapper">
      <div class="product-carousel__header">
        <div class="product-carousel__box product-carousel__box--title">
          <p class="product-carousel__title">{{ title }}</p>
        </div>
        <div v-if=isShownSeeAllButton(id) class="product-carousel__box product-carousel__box--link" >
          <nuxt-link
            :to="$transformParamsForPort($route, { name: 'catalog-slug', params: { slug: id }})"
            class="product-carousel__link button button--stroke "
          >
          {{ $t('see-all-btn') }}
          </nuxt-link>
        </div>
        <div class="product-carousel__box product-carousel__box--buttons">
          <button :id="`previous-slide-${id}`" class="product-carousel__button" type="button" ref="prevArrow" aria-label="previous">
            <svg-icon name="arrow-down" class="product-carousel__ico"></svg-icon>
          </button>
          <button :id="`next-slide-${id}`" class="product-carousel__button product-carousel__button--next" aria-label="next" type="button" ref="nextArrow">
            <svg-icon name="arrow-down" class="product-carousel__ico"></svg-icon>
          </button>
        </div>
      </div>


      <component
        class="product-carousel__list"
        :is="slickCarousel"
        ref="slick"
        @init="handleInit"
        @afterChange="handleAfterChange"
        @destroy="handleDestroy"
        :options="slickCarouselOptions"
        :class="{ 'product-carousel__list--alone' : products.length === 1 }"
      >
        <div v-for="(product, index) in products" class="product-carousel__item" :key="index">
          <ProductPreviewCarousel
            v-if="!product.is_sponsored"
            :product="product"
            :index="index"
            class="product-preview--slider product-carousel__preview"
          />
          <ProductPreviewPromo
            v-else
            :product="product"
            class="product-carousel__preview"
          />
        </div>
      </component>

      <div v-if=isShownSeeAllButton(id) class="product-carousel__footer">
        <nuxt-link
          :to="$transformParamsForPort($route, { name: 'catalog-slug', params: { slug: id }})"
          class="product-carousel__link button button--stroke button--full"
        >
        {{ $t('see-all-btn') }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';

  import ProductPreviewCarousel from '@/components/product/previews/ProductPreviewCarousel.vue';
  import { onProductClickMixin } from '@/mixins/onProductClick';

  export default {
    components: {
      ProductPreviewCarousel,
      ProductPreviewPromo:() => import('@/components/product/previews/ProductPreviewPromo.vue'),
      Slick: () => import('vue-slick')
    },
    props: ['title', 'products', 'id'],
    mixins: [onProductClickMixin],
    data() {
      return {
        'slickCarousel': '',
        slickCarouselOptions: {
          swipeToSlide: true,
          infinite: false,
          slidesToShow: 5,
          slidesToScroll: 2,
          draggable: true,
          adaptiveHeight: true,
          centerPadding: '140px',
          nextArrow: `#next-slide-${this.id}`,
          prevArrow: `#previous-slide-${this.id}`,

          responsive: [
            {
              breakpoint: 1250,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 815,
              settings: {
                centerMode: false,
                rows: 2,
                slidesPerRow: 1,
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
        }
      };
    },
    mounted: function () {
      this.$nextTick(function () {
        this.slickCarousel = 'Slick';
      });
      $(this.$el).on('click', '[data-product-link]', (e) => this.onProductClickFunction.apply(this, [e]));
    },
    beforeDestroy() {
      $(this.$el).off('click', '[data-product-link]');
    },
    methods: {
      next() {
        this.$refs.slick.next();
      },
      prev() {
        this.$refs.slick.prev();
      },
      reInit() {
        this.$refs.slick.reSlick();
      },
      handleDestroy() {
        let collection = this.$refs.slick.$el.getElementsByClassName('slick-track')[0];
        if (collection === undefined) {
          collection = this.$refs.slick.$el;
        }
        const itms = Array.from(collection.children);

        if (window.innerWidth > 815) {
          this.$refs.slick.$el.setAttribute('style', 'display: flex; flex-wrap: no-wrap');
        } else {
          this.$refs.slick.$el.setAttribute('style', 'margin: 0 -2px;');
        }

        itms.forEach(el => {
          el.style.width = el.dataset.width;
        })
      },
      handleInit() {
        setTimeout(this.updatePreviewWidth, 2000);
      },
      handleAfterChange() {
        this.updatePreviewWidth();
      },
      updatePreviewWidth() {
        const collection = this.$refs.slick.$el.getElementsByClassName('slick-track')[0];
        const itms = Array.from(collection.children);

        itms.forEach(el => {
          if(el){
            el.getElementsByClassName('product-carousel__item')[0].dataset.width = el.style.width;
            if (window.innerWidth < 815 && el.getElementsByClassName('product-carousel__item').length > 1) {
              el.getElementsByClassName('product-carousel__item')[1].dataset.width = el.style.width;
            }
          }
        })
      },
      isShownSeeAllButton(id) {
        const slugsWithShownButton = ["bridge", "deck", "engineering", "galley", "interior"];
        return slugsWithShownButton.includes(id);
      }
    }
  }
</script>

<style lang="scss">
.product-carousel {
  padding-bottom: 60px;
  overflow-x: hidden;
  min-height: 650px;

  @media (--viewport-tablet) {
    padding-bottom: 49px;
  }
}

  .product-carousel__header{
    display: flex;
    align-items: center;
  }

  .product-carousel__title {
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 1.5rem;
    line-height: 1.041;
    color: var(--secondary-color-dark);
    font-weight: 600;
    font-family: var(--secondary-font);

    @media (--viewport-mobile) {
      font-size: 1.75rem;
    }


    @media (--viewport-tablet) {
      line-height: 1.125;
    }
  }

  .product-carousel__box--buttons {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .product-carousel__box--link {
    display: none;

    @media (--viewport-tablet) {
      display: block;
    }
  }

  .product-carousel__button {
    position: relative;
    width: 40px;
    height: 40px;

    @media (--viewport-tablet) {
      width: 64px;
      height: 64px;
    }

    &:hover {
      background: var(--color-grey-1);
    }
  }

  .product-carousel__button--next {
    margin-left: 8px;

    @media (--viewport-tablet) {
      margin-left: 16px;
    }

    .product-carousel__ico {
      fill: var(--brand-color);
      transform: rotate(-90deg) translate(-50%, -50%);
    }
  }

  .product-carousel__ico {
    position: absolute;
    width: 30px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: rotate(90deg) translate(-50%, -50%);
    transform-origin: top left;
    fill: var(--color-grey-5);
  }

  .product-carousel__list {
    position: relative;
    display: block;
    padding-top: 20px;

    @media (--viewport-tablet) {
      padding: 20px 0 61px 0;

      &:hover {
        z-index: 2;
      }
    }

    .slick-slider {
      height: 100%;
    }

    .slick-slide {
      opacity: 0;
      pointer-events: none;
    }

    .slick-active {
      opacity: 1;
      pointer-events: auto;
    }

    .slick-list {
      height: 100%;
      margin: 0 -1px;

      @media (--viewport-tablet) {
        margin: 0;
      }
    }

    .slick-track {
      height: 100%;
      display: flex;
    }

    &.product-carousel__list--alone {
      overflow: hidden;

      .slick-slide {
        & > div {
          height: 100%;
        }
      }
    }

    .slick-slide {
      display: inline-block;
      outline: none;
      vertical-align: top;
      position: relative;
      box-sizing: border-box;

      & > div {
        width: 100%;
        height: 50%;
        box-sizing: border-box;
      }

      @media (--viewport-tablet) {
        height: auto;

        & > div {
          height: 100%;
        }
      }
    }
  }

  .product-carousel__item {
    display: inline-block;
    vertical-align: top;
    height: 100%;
  }

  .catalog__product-carousel {
    .product-carousel__title {
      padding-right: 15px;
      font-size: 1.25rem;
      line-height: 1.041;
      color: var(--secondary-color-dark);
      font-weight: 600;
      font-family: var(--secondary-font);

      @media (--viewport-mobile) {
        font-size: 1.5rem;
      }


      @media (--viewport-tablet) {
        font-size: 2rem;
        line-height: 1.125;
      }
    }

    .product-carousel__list {
      position: relative;
      display: block;
      padding: 20px 0 40px 0;
      overflow-x: hidden;

      @media (--viewport-tablet) {
        padding: 20px 0 120px 10%;
      }
    }
  }

  .product-carousel__footer{
    padding-top: 10px;

    @media (--viewport-tablet) {
      display: none;
    }
  }

  
</style>
