import isUndefined from 'lodash/isUndefined';

export const pluralizeMixin = {
  methods: {
    pluralize(count, noun, suffix = 's') {
      if (isUndefined(count)) return '';
      return `${count} ${noun}${count !== 1 ? suffix : ''}`;
    }
  }
}
