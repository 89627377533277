<template>
  <div class="product-preview-badge">
    {{ title }}
  </div>
</template>

<script>
  export default {
    name: 'ProductPreviewBadge',
    props: {
      title: {
        type: String,
        required: true,
      }
    }
  }
</script>

<style scoped>
  .product-preview-badge {
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: var(--brand-color);
    color: #FFFFFF;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.3;
    border-radius: 32px;
  }

  .product-preview-badge--sponsored {
    padding: 6px 8px;
    position: absolute;
    top: 12px;
    left: 12px;
    background: #FDEFE8;
    font-size: .625rem;
    line-height: 1.33;
    color: var(--brand-color);
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 32px;
    z-index: 2;

    @media (--viewport-mobile) {
      font-size: .75rem;
    }

    @media (--viewport-tablet) {
      padding: 8px 16px;
    }
  }

</style>
