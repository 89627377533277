<template>
  <div class="product-price" :class="{ 'product-price--disabled': isDisabled }">
    <client-only>
      <div class="product-price__region product-price__item" v-if="isRegionUS">
        <span class="product-price__currency">$</span>
        <span class="product-price__value">{{ this.minPriceUsd | formatNumber }}</span>
      </div>
    </client-only>
    <div class="product-price__region product-price__item" :class="{ 'product-price__region--grey': isRegionUS }">
      <span class="product-price__currency">€</span>
      <span class="product-price__value">{{ this.minPrice | formatNumber }}</span>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: ['minPrice', 'minPriceUsd', 'isDisabled'],
    computed: {
      ...mapGetters({
        isRegionUS: 'shared/isRegionUS'
      })
    }
  }
</script>
<style scoped lang="scss">
  .product-price {
    padding-right: 30px;
    color: var(--brand-color);
    display: flex;
    flex-direction: column;

    @media (--viewport-tablet) {
      padding-right: 0;
    }
  }

  .product-price--disabled {
    color: var(--color-grey-5);
  }

  .product-price__region {
    display: flex;
    flex-wrap: wrap;

    &.product-price__region--grey {
      .product-price__item {
        color: var(--color-grey-5);
        font-size: .5rem;

        .product-price__currency {
          margin-bottom: 0;
          font-size: .4rem;
        }

        @media (--viewport-mobile) {
          font-size: .75rem;

          .product-price__currency {
            margin-bottom: 1px;
            font-size: .5rem;
          }
        }

        @media (--viewport-tablet) {
          font-size: 1rem;

          .product-price__currency {
            margin-bottom: -1px;
            font-size: .75rem;
          }
        }
      }
    }
  }

  .product-price__item {
    display: inline-flex;
    align-items: center;
    font-family: var(--secondary-font);
    font-size: .75rem;
    line-height: 1.3;
    font-weight: 600;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-feature-settings: "pnum" on, "lnum" on;

    & + .product-price__item {
      &:before {
        content: "–";
        display: inline-block;
        display: flex;
        justify-content: center;
        width: 15px;
      }
    }

    @media (--viewport-mobile) {
      font-size: 1rem;
    }

    @media (--viewport-tablet) {
      font-size: 1.25rem;
    }
  }

  .product-price__currency {
    font-size: .75rem;
    color: inherit;
    margin-right: 2px;
    margin-bottom: 0;

    @media (--viewport-tablet) {
      font-size: 1rem;
      margin-bottom: -3px;
    }
  }
</style>
